$total: 60 + 90 + 90 + 120 + 120 + 100;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search {
  display: flex;
  justify-content: space-between;
}

.varietySearch {
  display: flex;
  >div{
    margin-right: 10px;
  }
}

.searchInput {
  height: 40px;
}

.createButton {
  margin-bottom: 16px;
  flex: none;
  align-self: flex-end;
}

.table {
  min-width: 640px;
  overflow: auto;

  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tableRow:hover {
    td {
      background-color: #fff;
    }
  }

  .tableColumn60 {
    width: 60 / $total;
  }

  .tableColumn90 {
    width: 90 / $total;
  }

  .tableColumn120 {
    width: 120 / $total;
  }

  .tableColumn160 {
    width: 160 / $total;
  }

  .tableColumnAction {
    width: 100 / $total;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .tableAction {
    color: #01bcd4;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
  }
}
