@import "~gago-mapbox-gl/dist/mapbox-gl.css";
.mapboxgl-map {
  font: unset;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: BebasNeue;
  src: url("./BebasNeue-Regular.woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}
input {
  background-color: transparent;
}
input:-webkit-autofill {
  /* 选择历史记录的文字颜色*/
  -webkit-text-fill-color: #333!important;
}

.mengcao-select .ant-select-arrow{
    color: rgba(101, 251, 255, 1)!important;
    background-color: rgba(0, 42, 66, 0.2)!important;
}
.mengcao-select .ant-select-arrow{
  color: rgba(101, 251, 255, 1)!important;
}

.mengcao-cascader{
  color: rgba(101, 251, 255, 1)!important;
  background-color: rgba(0, 42, 66, 0.2)!important;
}
.mengcao-cascader .ant-cascader-picker-arrow{
color: rgba(101, 251, 255, 1);
}

.mengcao-select-multiple .ant-select-selection{
  background-color: rgba(0, 42, 66, 0.2)!important;
}


.control-width-660 .ant-modal{
  width: 660px!important;
}

.table-font-control  * {
  font-size: 20px;
}

.table-font-control  *  .ant-pagination * {
  font-size: 16px;
}

.transform-control {
  transform: translate3d(0px, 0px, 0px);
}

#root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
