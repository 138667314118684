.deviceWrapper {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  height: 100vh;
  overflow: auto;

  .deviceBody {
    width: 100%;
    margin-top: 50px;
  }

  .addDevice {
    float: right;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .addOperation {
    margin-right: 10px;
  }
}
