
/* stylelint-disable */
.roleManageWrapper {
  height: 300px;
  overflow: auto;
  width: 250px;

  :global {
    .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
      display: none !important;
    }
    .tree-container li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher), li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper, li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
      margin: 0;
    }
    .tree-container .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
      display: none;
    }
    .tree-container > .ant-tree li .ant-tree-node-content-wrapper {
      margin-left: -10px !important;
      color: red;
    }
  }
}
